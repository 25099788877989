<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <h3>
            <span v-if="component_type == 'edit'"
              >Edit Deal Information
              <v-progress-circular
                indeterminate
                class="ml-3"
                color="primary"
              ></v-progress-circular></span
            ><span v-if="component_type == 'add'">Add Deal</span>
          </h3>
          <v-card v-if="error" class="danger mb-6">
            <v-card-subtitle class="red--text"
              >There was an error.</v-card-subtitle
            >
            <v-card-text class="red--text">{{ error }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            <span v-if="component_type == 'edit'">Edit Deal Information</span>
            <span v-if="component_type == 'add'">Add Deal</span>
          </h3>

          <!-- <p class="py-4">
            This is where we are going to add the deal. Deals are a critical
            component in getting us those sweet sweet fees. Simply add their
            name, type, and areas they lend in and BOOM! Retirement.
          </p> -->
          <v-form
            @submit="submitCreateDeal"
            ref="form"
            v-model="formRules.valid"
          >
            <v-row v-if="component_type == 'edit'">
              <v-col>
                <v-select
                  label="Deal Status"
                  v-model="dealData.status"
                  :items="status_options"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="component_type == 'edit'">
                <!-- <v-text-field 
                  v-model="dealData.public_name"
                  label="Public Name"
                  :rules="formRules.required"
                ></v-text-field> -->
                <span>{{ dealData.public_name }}</span>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="dealData.internal_name"
                  label="Deal Internal Name (Only visible to us)"
                  :rules="formRules.required"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="dealData.description"
                  label="Internal Description"
                  outlined
                  :rules="formRules.required"
                ></v-textarea>
              </v-col>
            </v-row>

            <br />

            <v-row>
              <v-col>
                <v-select
                  :items="deal_province_options"
                  v-model="dealData.province"
                  label="Province"
                  :rules="formRules.required"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  :items="deal_purpose_options"
                  v-model="dealData.deal_purpose"
                  label="Deal Purpose"
                  :rules="formRules.required"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  :items="deal_type_options"
                  v-model="dealData.deal_type"
                  label="Deal Type"
                  :rules="formRules.required"
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="component_type == 'add'">
              <v-col>
                <v-select
                  :items="kanban_stage_options"
                  v-model="dealData.kbn_stg.id"
                  label="Deal Stage"
                  :rules="formRules.required"
                />
              </v-col>
            </v-row>

            <v-row v-if="component_type == 'edit'">
              <v-col>
                <v-select
                  :items="kanban_stage_options"
                  v-model="dealData.kbn_stg.id"
                  label="Deal Stage"
                  :rules="formRules.required"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-card v-if="submitError" class="danger mb-6">
                  <v-card-subtitle class="red--text"
                    >There was an error.</v-card-subtitle
                  >
                  <v-card-text class="red--text">{{ submitError }}</v-card-text>
                </v-card>
                <v-btn
                  v-if="component_type == 'add'"
                  :disabled="formLoading"
                  @click="submitCreateDeal"
                  >Create Deal
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  ></v-progress-circular
                ></v-btn>
                <v-btn
                  v-if="component_type == 'edit'"
                  :disabled="formLoading"
                  @click="submitUpdateDeal"
                  >Update Deal
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  ></v-progress-circular
                ></v-btn>
                <v-btn
                  dark
                  class="ml-4"
                  v-if="component_type == 'edit'"
                  @click="
                    $router.push({
                      path: `/dashboard/admin/deal/read/${deal_id}`,
                    })
                  "
                  >Cancel</v-btn
                >
                <v-btn
                  dark
                  class="ml-4"
                  v-if="component_type == 'add'"
                  @click="$router.push({ path: `/dashboard/deal/list` })"
                  >Go To Deal List</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from '@/plugins/API';

export default {
  name: 'DealAdd',
  props: ['dealId', 'deal_id'],
  data() {
    return {
      component_type: '',
      kanban_stage_options: [
        {
          value: 0,
          text: 'Choose Here',
        }
      ],

      formLoading: false,
      componentLoading: true,
      error: '',
      submitError: '',
      formRules: {
        valid: false,
        required: [(value) => !!value || 'This is a required field.'],
      },
      dealData: {
        public_name: '',
        internal_name: '',
        description: '',
        deal_purpose: 'refinance',
        deal_type: 'approval',
        province: '',
        status: '',
        kbn_stg: {
          id: null,
        },
      },
      deal_province_options: [
        { value: 'AB', text: 'Alberta' },
        { value: 'BC', text: 'British Columbia' },
        { value: 'ON', text: 'Ontario' },
      ],
      deal_purpose_options: [
        { value: 'refinance', text: 'Refinance' },
        { value: 'purchase', text: 'Purchase' },
        { value: 'switch-transfer', text: 'Switch/Transfer' },
      ],
      deal_type_options: [
        { value: 'approval', text: 'Approval' },
        { value: 'preapproval', text: 'Pre-Approval' },
      ],
      status_options: [
        { value: 'draft', text: 'Draft' },
        { value: 'active', text: 'Active' },
        { value: 'archive', text: 'Archive' },
        { value: 'funded', text: 'Funded' },
        { value: 'awarded', text: 'Awarded' },
      ],
    };
  },
  methods: {
    submitCreateDeal() {
      this.formLoading = true;
      this.submitError = '';

      this.$refs.form.validate();

      if (this.formRules.valid) {

        console.log('data sent to createDeal: ', this.dealData)
        API()
          .post('/api/internal-admin/createDeal', this.dealData)
          .then((res) => {
            console.log(res.data);
            this.formLoading = false;
            console.log(res);
            
            // update kanban stage item
            API().post('api/kanban/update_kanban_stage_item', {
              stage_id: this.dealData.kbn_stg.id,
              item_id: res.data.id,
              type: 'Deal',
              choice: 'New'
            });

            this.$router.push(`/dashboard/admin/deal/read/${res.data.id}`);
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },

    submitUpdateDeal() {
      this.formLoading = true;
      this.submitError = '';

      this.$refs.form.validate();

      if (this.formRules.valid) {
        API()
          .patch(`/api/internal-admin/update_deal/`, this.dealData)
          .then((res) => { 
            this.formLoading = false;
            console.log(res.data);

            // update kanban stage item
            API().post('api/kanban/update_kanban_stage_item', {
              stage_id: this.dealData.kbn_stg.id,
              item_id: res.data.id,
              type: 'Deal',
              choice: 'New'
            });

            this.$router.push(`/dashboard/admin/deal/read/${res.data.id}`);
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },

    async getKanbanStages() {
      try {
        const kanban_stages = await API().get('api/kanban/get_kanban_deal_data');

        kanban_stages.data.forEach((el) => {
          this.kanban_stage_options.push({
            value: el.id,
            text: el.stage_name,
          });
        });

      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    if (this.$route.name == 'deal_add') {
      this.component_type = 'add';
      this.componentLoading = false;
      this.getKanbanStages();
    } else if (this.$route.name == 'deal_edit') {
      this.component_type = 'edit';

      API()
        .get(`api/internal-admin/dealRead/${this.deal_id}`)
        .then((res) => {
          this.dealData = res.data;
          this.componentLoading = false;

          if(this.dealData.kbn_stg === null) {
            this.dealData.kbn_stg = { id: 0 }
          }

          console.log('dealData: ', this.dealData)
        })
        .catch((e) => {
          console.log(e);
        });
      this.getKanbanStages();
    }
  },
};
</script>

<style></style>
